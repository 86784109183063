/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Row, Col, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import UtileLinks from '../helpers/usefull_link';
import FormMenu from '../helpers/FormMenu';

// Fake APIS
import API from '../shared/APIs';

// Style
import '../page-styles/useful-links.scss';
import '../page-components/usefulLinks/index.scss';

/* -------------------------------------------------------------------------- */
/*                        All Usefull Links  Page                             */
/* -------------------------------------------------------------------------- */

function AllusefullLinksPage({ location }) {
  /* ********************************** HOOKS ******************************** */
  // USEFULL LINKS DATA
  const [linksData, setLinksData] = useState();
  const [loader, setLoader] = useState(true);

  // Search
  const [searchInput, setSearchInput] = useState('');

  /* --------------------------------- CONST -------------------------------- */
  const getSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  /* -------------------------------- HELPERS ------------------------------- */
  useEffect(() => {
    axios
      .get(API('useful-links'))
      .then(({ data: { data } }) => {
        setLinksData(data);
        setLoader(true);
      })
      .then(() => setLoader(false))
      .catch((error) => error);
  }, []);

  /* -------------------------------- CONST --------------------------------- */
  const usefullLinks = linksData
    ?.filter((filtredUsefullLink) =>
      filtredUsefullLink?.attributes?.title
        .toLowerCase()
        .includes(searchInput.toLowerCase()),
    )
    .map((link) => {
      const USEFUL_LINK_TITLE = link?.attributes?.title;
      const USEFUL_LINK_URL = link?.attributes?.url;
      return (
        <Col
          key={link.id}
          className="single-utlie-link fluid"
          xxl={12}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          fluid
        >
          <UtileLinks title={USEFUL_LINK_TITLE} URL={USEFUL_LINK_URL} />
        </Col>
      );
    });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Liens Utiles" />
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link
              className="breadcrumb__link"
              to={`/${location.pathname.split('/')[1]}`}
            >
              Liens utiles
            </Link>
          </Container>
        </Visible>
      </Container>
      <Container className="usefullLinksPage">
        <FormMenu getSearchInput={getSearchInput} />
        <Row className="top fluid">
          {loader ? <Container className="loader fluid" /> : usefullLinks}
        </Row>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

AllusefullLinksPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AllusefullLinksPage;
