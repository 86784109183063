/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container } from 'react-grid-system';
import { Link } from 'gatsby';

// Assets
import globalIcon from '../images/usefullLinks/global.svg';

// Style
import '../page-components/usefulLinks/index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Links To Use                              */
/* -------------------------------------------------------------------------- */

function UtileLinks({ title, URL }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="single-links-container flex items-center fluid" fluid>
      <img src={globalIcon} alt="icon d'un lien utile" />
      <Container className="content fluid" fluid>
        <h5>{title}</h5>
        <Link to={URL} className="usefull-link">
          {URL}
        </Link>
      </Container>
    </Container>
  );
}
export default UtileLinks;

UtileLinks.propTypes = {
  title: PropTypes.string.isRequired,
  URL: PropTypes.string.isRequired,
};
